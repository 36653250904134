$scroll-color: #eba954;

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding-top: 20px;
  border-top: solid 1px #d9d9d9;
}

.formItems {
  padding-bottom: 40px;
  padding-left: 40px;
}

.formItem {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 25px;
}

.formItemCol {
  position: relative;
  margin-bottom: 5px;
}

.formTitle {
  margin: 0;
  padding: 40px 0;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  font-size: 33px;
  text-align: center;
}

.checkbox {
  margin-left: 5px;
}

.errorMsg {
  color: red;
}

.formatStyle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  text-align: center;
}

// author
.titleContent {
  margin-top: 1rem;
  text-align: center;
}

.showContent {
  display: inline-block;
  vertical-align: top;
}

.inputStyle {
  width: 200px;
}

.uploadImg {
  display: inline-block;
}

.imgContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  max-height: 600px;
  overflow-y: auto;
}

.popStyle {
  height: 80vh;
}

.imgWarning {
  vertical-align: top;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}

.editorContent {
  display: inline-block;
  max-width: 80%;
  vertical-align: top;
}

.subBtnStyle {
  width: 92px;
  height: 40px;
}

.backBtnStyle {
  width: 92px;
  height: 40px;
  margin-left: 23px;
}

.gapBetweenLine {
  width: 100%;
  height: 10px;
  margin-top: 39px;
  margin-bottom: 39px;
  background-color: #f4f4f4;
}

.imgContent::-webkit-scrollbar {
  width: 4px;
}

.imgContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px $scroll-color;
  background: rgba($color: $scroll-color, $alpha: 1);
}

.imgContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px $scroll-color;
  border-radius: 0;
  background: rgba($color: $scroll-color, $alpha: 0.1);
}
