@import 'theme.scss';

.breadcrumbWrap {
  background-color: #fff;
}

.breadcrumb {
  margin-left: 20px;
  background-color: #fff;
  padding: 20px 20px 20px 10px;

  :global {
    span:last-child > span {
      color: $primary-color;
    }
  }
}

.breadcrumbText {
  color: #a8a8a8 45%;
  font-weight: normal;
  cursor: pointer;
}
