.formContainer {
  height: 100%;
  background: #fff;
  padding-left: 20px;
}

.formItem {
  padding: 42px 0;
  border-bottom: 1px solid #d9d9d9;
}

.formItemCol {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 24px;
}

.formLabel {
  width: 100px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.input {
  width: 387px;
  height: 45px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.textArea {
  width: 387px;
  height: 80px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
}

.formChoosePlayer {
  padding: 42px 0;
}

.buttons {
  padding-left: 125px;
}

.submitBtn {
  background: #dfb854;
  color: #fff;
  border-radius: 4px;
  margin-right: 24px;
}

.addPlayer {
  color: #dfb854;
  font-size: 14px;
  border: 0;
  padding-left: 125px;
  padding-bottom: 45px;
}

.ImageWarp {
  padding-right: 28px;
}

.image {
  width: 55px;
  height: 55px;
}

.ImageRelative {
  position: relative;
}

.icon {
  position: absolute;
  right: -6px;
  top: -6px;
  z-index: 22;
  font-size: 18px;
  color: #ddd;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

:global {
  .submitBtn .ant-btn.active {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }

  .submitBtn .ant-btn:active {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }

  .submitBtn .ant-btn:hover {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }

  .submitBtn .ant-btn:focus {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }
}
