
.modalContent {
  :global .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.modalInterval {
  padding: 0 25px;
}

.modalTitle {
  width: 100px;
  line-height: 45px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.modalInput {
  width: 100% !important;
  height: 40px;
}

.footerList {
  display: flex;
  width: 100%;
  height: 60px;
  line-height: 20px;
  padding: 0 24px;
  border-radius: 0 0 4px 4px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  box-shadow: 4px 0 12px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: flex-end;
}

.send {
  color: #fff;
  margin-left: 16px;
  background-color: rgba(223, 184, 84, 1);
}
