@import 'theme.scss';

@mixin btnCommon {
  font-size: 1.2rem;
  cursor: pointer;
}

.btns {
  min-width: 80px;
  display: flex;
  justify-content: center;
}

.editBtn {
  @include btnCommon();

  margin-right: 0.8rem;
  color: $primary-color;
}

.deleteBtn {
  @include btnCommon();

  color: red;
}
