.previews {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  user-select: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .imageBox {
    width: 100%;
    height: fit-content;
    max-height: 100%;

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.tipBox {
  text-align: center;

  .tipIcon {
    font-size: 52px;
    color: #eee;
  }

  .tipText {
    font-size: 13px;
    color: rgb(245, 245, 245);
    margin: 16px 0px 0px;
  }
}
