.playerListWarp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.listItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listRelative {
  position: relative;
  margin-bottom: 22px;
}

.modalFixed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
}

.none {
  display: none;
}

.name {
  font-size: 14px;
  color: #101010;
  padding-top: 8px;
}

.img {
  width: 120px;
  height: 120px;
}
