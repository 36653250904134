.userLayout {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-size: cover;
}

.userContent {
  position: absolute;
  width: 450px;
  background: #fff;
  border-radius: 5px;
}

.layerMask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
