.selectWrap {
  margin-left: 30px;
  cursor: pointer;
  padding-top: 3px;
}

.selcetText {
  width: 92px;
  height: 27px;
  font-size: 18px;
  line-height: 27px;
}
