.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 368px;
  margin: 0 auto;
}

.formItems {
  padding-bottom: 40px;
}

.formItem {
  position: relative;
  flex-direction: column;
  margin-bottom: 25px;
}

.formItemCol {
  position: relative;
  margin-bottom: 5px;
}

.formTitle {
  margin: 0;
  padding: 40px 0;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  font-size: 33px;
  text-align: center;
}

.checkbox {
  margin-left: 5px;
}
