.produceContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.bodyShow {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.playerInfos {
  padding-left: 17px;
}

.playerName {
  font-size: 19px;
  font-weight: bold;
}

.playerID {
  font-size: 15px;
  padding-left: 15px;
}

.leftPadding {
  padding-left: 15px;
}

.playerImg {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.footerShow {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.wrapperImg {
  // display: inline-block;
  // 每个flex元素占的宽度相同
  margin: 0 16px 0 0;
}

.playerLevel {
  height: 18px;
  line-height: 17px;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  text-align: center;
  padding: 2px 10px;
  border-radius: 12px;
  background-color: rgba(223, 184, 84, 1);
}

.headerImg {
  max-height: 200px;
  object-fit: contain;
  // 使图片等比拉伸，可能会被裁减
  vertical-align: bottom;
}

.guardian {
  display: inline-block;
  padding-left: 17px;
}

.guardianImg {
  width: 30px;
  height: 26px;
  padding-left: 5px;
  border-radius: 50%;
  vertical-align: middle;
}

.guardianName {
  padding-left: 5px;
}

// common
.txtFont {
  font-size: 15px;
  font-weight: 'bold';
}

.gapBetweenLine {
  width: 100%;
  height: 10px;
  margin-top: 39px;
  margin-bottom: 39px;
  background-color: #f4f4f4;
}

.padBetweenLine {
  width: 100%;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 11px;
  background-color: #f4f4f4;
}
