
.createItem {
  display: flex;
}

.messageItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
  margin-right: 18px;
  width: 309px;
  height: 69px;
  box-sizing: border-box;
  border-left: 4px solid #dfb854;
  padding-left: 17px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 1);
}

.times {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  margin-bottom: 7px;
  display: flex;
}

.startTime {
  margin-right: 15px;
}

.endTime {
  margin-left: 15px;
}

.name {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: left;
}

.actionsList {
  margin-top: 20px;
}
