.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 42px;
  box-sizing: border-box;
  background-color: #fff;
}

.fullScreen {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
  padding-bottom: 42px;
}

.globalInterval {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 44px;
}

.toBeCenter {
  line-height: 23px;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;

  :global .ant-input {
    width: 387px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
  }
}

.title {
  width: 100px;
  text-align: center;
}

.description {
  line-height: 23px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;

  :global .ant-input {
    width: 387px;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
  }
}

.inputStyle {
  width: 387px;
  height: 40px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: left;
  border: 1px solid rgba(217, 217, 217, 1);
}

.toBeHorizontal {
  display: flex;
}

.introduction {
  display: flex;
  margin-top: 34px;
}

.footer {
  margin-top: 41px;
  display: flex;
  padding-left: 100px;
}

.submitBtn {
  background: #dfb854;
  color: #fff;
  border-radius: 4px;
  margin-right: 24px;
}

.return {
  width: 92px;
  line-height: 40px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  border: 1px solid rgba(187, 187, 187, 1);
}

.uploadImg {
  display: inline-block;
}

.imgFormat {
  margin-top: 9px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  text-align: left;
}

.imgContent {
  display: inline-block;
  vertical-align: top;
  max-width: 400px;
  max-height: 400px;
}

.imgWarning {
  padding-left: 15px;
  vertical-align: top;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: left;
}
