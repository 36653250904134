.previewList {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}

.toBeCenter {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  &:hover {
    opacity: 1;
    color: #eee;
    background: rgba(0, 0, 0, 0.3);
  }
}

.previewItem {
  height: auto;
  position: relative;
  margin: 3px;
}

.carouselContent {
  height: 680px;
}

.fullView {
  width: 100%;
  height: 680px;
  padding: 20px;
  display: flex;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
}

.fullImage {
  width: 100%;
  text-align: center;
  max-width: 500px;
}

.openModal{
  font-size: 20px;
}

.previewImage {
  width: 42px;
  height: 28px;
  object-fit: cover;
  cursor: pointer;
}

.fit {
  img {
    object-fit: contain;
  }
}
