// .layout {}

.layoutMain {
  flex-direction: row !important;
  min-height: 93vh !important;
}

.layoutContent {
  position: relative;
  padding: 20px;
  color: #666;
}
