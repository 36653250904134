@import 'theme.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  background: #fff;
  box-shadow: none;
}

.headerMenu {
  display: flex;
  align-items: center;

  :global {
    .ant-menu-horizontal {
      border-bottom: none;
    }
  }
}

.headerLeft {
  > div {
    display: flex;
    align-items: center;
  }
}

.title {
  font-size: 28px;
  color: rgba(24, 31, 71, 1);
  font-weight: normal;
  padding-left: 20px;
}

.headerMenuItem {
  border-bottom: unset !important;
}

.userInfo {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
  cursor: pointer;

  .userProfile {
    display: inline-block;
    color: #666;
    text-align: center;
    margin-left: 5px;

    .userName {
      font-size: 16px;
    }
  }

  .downIcon {
    margin-left: 2px;
  }
}

.userInfoDropdownMenu {
  :global {
    .ant-dropdown-menu-item {
      min-width: 80px;

      i {
        margin-right: 5px;
      }
    }
  }
}
