.gameAreaMask {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: rgba($color: gray, $alpha: 0.3);
  z-index: 100;
  top: 20px;
  left: 20px;
}

.maskText {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    color: #000;
    font-size: 32px;
  }
}
