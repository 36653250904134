@import 'theme.scss';

.page {
  background: white;
  padding: 1rem;
}

.subBtnStyle {
  height: 32px;
  width: 65px;
  margin-left: 29px;
}

.inputStyle {
  width: 272px;
}
