@import 'theme.scss';

// 其他信息
.titleIntroduction {
  color: #131417;
  font-size: 20px;
  font-weight: bold;
}

.selfIntroduction {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 19px;
}

.aboutHerContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.auditBtnStyle {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.passedBtnStyle {
  height: 32px;
  text-align: center;
  padding: 4px 10px;
}

.rejectedBtnStyle {
  height: 32px;
  color: #fff;
  text-align: center;
  padding: 4px 10px;
  margin-left: 29px;
  border-color: transparent;
  background-color: #e63037;
}

// common
.txtFont {
  font-size: 15px;
  font-weight: 'bold';
}

.gapBetweenLine {
  width: 100%;
  height: 10px;
  margin-top: 39px;
  margin-bottom: 39px;
  background-color: #f4f4f4;
}

.padBetweenLine {
  width: 100%;
  height: 1px;
  margin-bottom: 11px;
  background-color: #f4f4f4;
}
