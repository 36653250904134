@mixin container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.uploadImgContainer {
  position: relative;
  margin: 5px 15px 5px 0;
}

.imgBox {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #f4f4f4;
  background: #f9f9f9;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
}

.uploadBtn {
  @include container;
  opacity: 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.15);

  .text {
    margin-top: 8px;
    width: 100%;
    height: 20px;
    color: rgba(111, 105, 105, 0.75);
    font-size: 14px;
    text-align: center;
  }

  .plus {
    font-size: 28px;
  }
}

.videoBox {
  width: 100%;
  height: 100%;

  .uploads {
    position: absolute;
    width: 36px;
    height: 36px;
    background: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    cursor: pointer;

    .plus {
      font-size: 18px;
    }
  }
}

.options {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.15);
  }

  .plus {
    color: #eee;
    font-size: 12px;
    margin: 6px;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.35);

    &:hover {
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

:global(.prism-player .prism-big-play-btn) {
  left: calc(50% - 32px) !important;
}
