.page {
  background: white;
  padding: 1rem;
}

.mainTable {
  margin-top: 1rem;
}

.subBtnStyle {
  height: 32px;
  width: 65px;
}

.inputStyle {
  width: 260px;
  margin-right: 22px;
}
