@import 'theme.scss';

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  font-weight: bold;
  font-size: 36px;

  &:visited,
  &:link {
    color: $primary-color;
  }
}

.headLogo {
  height: 67px;
}
