.toBeCenter {
  flex-direction: row;
  justify-content: center;
  flex: 1;
  align-items: center;
  display: flex;
}

.videoPlayer {
  display: flex;
  width: 560px;
  height: 300px;
  align-items: center;
  flex-direction: column;
}
