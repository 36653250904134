.page {
  background: white;
  padding: 1rem;
}

.subBtnStyle {
  height: 32px;
  width: 65px;
}

.inputStyle {
  width: 260px;
  margin-right: 22px;
}

.mainTable {
  margin-top: 20px;
}

.col {
  display: flex;
  align-items: center;
  padding: 10px;

  .colSpan {
    width: 110px;
    text-align: right;
    padding-right: 6px;
  }
}

.infoBox {
  display: flex;
  justify-content: center;

  .infos {
    width: 150px;
    text-align: left;
    padding: 8px 0 8px 10px;
    box-sizing: border-box;

    p {
      margin-bottom: 0;
      line-height: 27px;
      white-space: nowrap;
    }
  }
}