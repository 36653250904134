.file {
  position: relative;
  display: inline-block;
  color: #6a6f7b;
  border-radius: 4px;
  padding: 8px 8px;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  margin-top: 5px;
  text-indent: 0;
  font-size: 14px;

  input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
  }
}

.file:hover {
  background-color: rgba(#000, 0.05);
}
