.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding-top: 20px;
  border-top: solid 1px #d9d9d9;
}

.formItems {
  padding-bottom: 40px;
  padding-left: 40px;
}

.formItem {
  position: relative;
  flex-direction: column;
  margin-bottom: 25px;
}

.formItemCol {
  position: relative;
  margin-bottom: 5px;
}

.formTitle {
  margin: 0;
  padding: 40px 0;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  font-size: 33px;
  text-align: center;
}

.checkbox {
  margin-left: 5px;
}

.errorMsg {
  color: red;
}

// author
.titleContent {
  display: inline-block;
  margin-right: 15px;
  width: 100px;
  text-align: right;
}

.showContent {
  display: inline-block;
  vertical-align: top;
}

.inputStyle {
  width: 400px;
  height: 45px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.textArea {
  width: 400px;
  height: 80px;
  font-size: 14px;
}

.uploadImg {
  display: inline-block;
}

.imgContent {
  display: inline-block;
  vertical-align: top;
}

.aliImg {
  max-width: 400px;
}

.formatStyle {
  color: rgba($color: #000, $alpha: 0.45);
}

.imgWarning {
  color: rgba(0, 0, 0, 0.25);
  margin: 10px 0 0 0;
  text-align: center;
}

.editorContent {
  display: inline-block;
  max-width: 80%;
  vertical-align: top;
}

.subBtnStyle {
  width: 92px;
  height: 40px;
  margin-left: 115px;
}

.backBtnStyle {
  width: 92px;
  height: 40px;
  margin-left: 23px;
}
