// 介绍
.basicInfoContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.titleIntroduction {
  color: #131417;
  font-size: 20px;
  font-weight: bold;
}

.selfIntroduction {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 19px;
}

.infoShow {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

// common
.txtFont {
  font-size: 15px;
  font-weight: 'bold';
}

.gapBetweenLine {
  width: 100%;
  height: 10px;
  margin-top: 39px;
  margin-bottom: 39px;
  background-color: #f4f4f4;
}

.padBetweenLine {
  width: 100%;
  height: 1px;
  margin-bottom: 11px;
  background-color: #f4f4f4;
}
