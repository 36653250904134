.gameAreaDetailWrap {
  position: relative;
  padding: 0 40px 20px 40px;
  background-color: #fff;
}

.formItemCol {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  margin-top: 24px;

  :global .ant-modal-footer {
    box-shadow: 4px 0 12px 0 rgba(0, 0, 0, 0.2);
  }

  :global .ant-modal-header {
    background-color: rgba(250, 250, 250, 1);
  }
}

.addScheduleBtn {
  cursor: pointer;
}

.formLabel {
  width: 100px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.input {
  width: 387px;
  height: 45px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.submitBtn {
  background: #dfb854;
  color: #fff;
  border-radius: 4px;
  margin-right: 24px;
  width: 76px;
  font-size: 14px;
}

:global {
  .submitBtn .ant-btn.active {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }

  .submitBtn .ant-btn:active {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }

  .submitBtn .ant-btn:hover {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }

  .submitBtn .ant-btn:focus {
    background: #dfb854;
    border: 1px solid #dfb854;
    color: #fff;
  }
}

.buttons {
  padding-left: 100px;
  padding-top: 60px;
}

.rangePickerWrap {
  display: flex;
  align-items: center;
}

.formatTips {
  padding-left: 100px;
  font-size: 14px;
  color: rgba($color: #000, $alpha: 0.45);
}

.suggestion {
  margin: 20px 0 0 10px;
  color: rgba($color: #000, $alpha: 0.25);
}

.addMessage {
  margin-top: 12px;
  color: #dfb854;
  font-size: 18px;
}

.addMessageIcon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-color: #dfb854;
  border-width: 2px;
  text-align: center;
  border-style: solid;
}
