.warn {
	color: red;
	line-height: 32px;
}

.info {
	line-height: 32px;
	margin-bottom: 0;

	span {
		margin-right: 16px;
	}
}

.center {
	display: flex;
	align-items: center;
	height: 32px;
}

.imageBox {
	display: flex;
	flex-wrap: wrap;
}
